const timetableHeaderTable = [
  { name: "scheduleType", title: "График" },
  { name: "workDayConst", title: "Кол-во рабочих дней" },
  { name: "calculationType", title: "Тип" },
  { name: "changeSchedule", title: "Изменить" },
]

const calculationTypes = [
  {
    title: 'Календарный расчет', 
    value: 'CALENDAR'
  },
  {
    title: 'Фиксированное значение', 
    value: 'CONST'
  },
  {
    title: 'Рассчитать автоматически',
    value: 'CALCULATE'
  },
  {
    title: 'Табель',
    value: 'SHEET'
  },
]

export {
  timetableHeaderTable,
  calculationTypes
}
